/*

Yellowish	253	251	231	#FDFBE7
Dark Grey	88	76	67	#584C43
Middle Grey	152	143	130	#988F82
Tan		    238	230	216	#EEE6D8 */

/*------------------------------------------*/
:root {
  --darkgrey-color: #483c33;
  --orange-color: #de6c46;
  --yellow-color: #fdfbe7;

  --secondary-text-color: #265077;
  --secondary-bg-color: white;

  --main-grid-columns: 1fr 10fr 1fr;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
