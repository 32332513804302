.footer {
  font-size: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: var(--main-grid-columns);
}

.footer-center {
  text-align: center;
  font-style: italic;
}
