.page {
  height: 100vh;
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 10vh 87vh 3vh;
}

.body {
  width: 100vw;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: var(--main-grid-columns);
}

.body-left {
  /* border-top: 1px dashed var(--yellow-color); */
  display: block;
}

.body-middle {
  /* background-color: blueviolet; */
  padding: 0px 5px 0px 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  display: grid;
  grid-auto-flow: row;
  overflow-x: hidden;
  overflow-y: auto;
}

.body-inner {
  /* background-color: teal; */
  padding: 5px 240px 5px 40px;
  border: 1px solid brown;
  background-color: #fdfbe7;
  /*   
Dark Grey	88	76	67	#584C43 */
}

/* div {
  border: 1px solid brown;
} */
