.header {
  font-size: 30px;
  text-align: center;
  display: grid;
  grid-template-columns: var(--main-grid-columns);
  justify-items: center;
  align-items: center;
}

.header-title {
  font-size: 50px;
}
